import axios from 'axios';
import React, { useEffect} from 'react';
import { GET_ANSWER, ISAAC_API_URL } from '../constants';
import { Collapse, Navbar, NavbarToggler } from 'reactstrap';
import './NavMenu.css';
import hashing_basics from './assets/Hashing and Dictionaries_default.mp4';
import { Markdown } from './Markdown';
import OpenAI from 'openai';
import { coerceInteger } from 'openai/core.mjs';
import { useRef } from 'react';
import def from 'ajv/dist/vocabularies/discriminator';
import { VideoChat } from './VideoChat';

const assistantId = 'asst_sgKag2BgYagRVkzfCVvRj5hg';
const threadId = 'msg_bea8iFHZpzoI8LY9fz2xb9Sn';

const openai = new OpenAI({
    apiKey:'sk-fe_DRkn_VidzaqTg7as4k93HQqoC9LfZn_xlaF1EEPT3BlbkFJSmtOH4J7whq3fH_meoK6ZituDSkwbzeT9pMCr2zQkA',
    dangerouslyAllowBrowser: true,
  });



export function VideoPlayer_hooked() {
    const videoRef = useRef(null);
    const chatRef = useRef(null);
    let question_posed = false;
  
    useEffect(() => {
      if (videoRef.current) {
        // Access video properties and methods
        console.log('Video duration:', videoRef.current.duration);
        videoRef.current.play(); 
      }
    }, []);

    function onClick()  {
        console.log('Time Stamp:', videoRef.current.currentTime);
        videoRef.current.play();
    }

    function onTimeUpdate() {
        console.log('Time Stamp:', videoRef.current.currentTime);

        if (videoRef.current.currentTime > 380 && !question_posed) {
            question_posed = true;
            videoRef.current.pause();
            chatRef.current.clear();
            chatRef.current.setState({
              conversation: [{"role":"system", "content":"Please tutor the user. Initial question is: What are basic qualities of a good hash function?"}],

                md_conversation:[
                    <div style={{ textAlign: "left", border: "1px solid lightgreen", borderRadius: "5px", padding: "5px", marginBottom: "10px" }}>
                        <span >🤖</span> {/* Display the assistant's icon */}
                        <Markdown content="What are basic qualities of a good hash function?"></Markdown>
                    </div>],
                said:''
            });
    }
  }

  
    return (
      <div>
        <div>
          <video onTimeUpdate={onTimeUpdate} ref={videoRef} onClick={onClick} width={900} controls src={hashing_basics}>
          {/* <source src="./assets/Hashing and Dictionaries_default.mp4" type="video/mp4" /> */}
        
        </video>

        </div>
        <div>
          <p></p>
          <VideoChat ref={chatRef}/>

        </div>
      </div>
      );
  }
export default VideoPlayer_hooked