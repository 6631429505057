import { For_business } from "./components/for_business";
import { Axioms } from "./components/axioms";
import { VideoPlayer_hooked } from "./components/VideoPlayer_hooked";
import { Flows } from "./components/Flows";
import { Home } from "./components/Home";
import { Docs } from "./components/Docs";
import { Portfolio } from "./components/portfolio";
import React from "react";


const AppRoutes = [
    {
        path: '',
        element: <Home />
    },
     {
        path: '/VideoPlayer_hooked',
        element: <VideoPlayer_hooked />
    },   
    {
        path: '/axioms',
        element: <Axioms />
    },    
    {
        path: '/flows',
        element: <Flows />
    },
    {
        path : '/for_business',
        element: <For_business />
    },
    {
        path: '/docs',
        element: <Docs />
    },
    {
        path: '/Portfolio',
        element: <Portfolio />
    }
];

export default AppRoutes;
