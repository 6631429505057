import React, { Component} from 'react';
import ReactMarkdown from 'react-markdown';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {materialDark} from 'react-syntax-highlighter/dist/esm/styles/prism';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';

var inline_pattern = /[^\n]*?\$.+\$[^\n]*/g;
var block_pattern = /\$\$.+\$\$/g;

export class Markdown extends Component {
  static displayName = Markdown.name;
  
  constructor(props) {
    super(props);

    var return_div = <Latex> Place holder content </Latex>;

    var block_matches = this.props.content.match(block_pattern);
    var split_by_block = this.props.content.split(block_pattern);

    if (block_matches == null){
      return_div = this.reformat_inline(this.props.content);
      this.state = {
        content: this.reformat_inline(this.props.content)
      }
      return;
    }else{
    return_div = this.reformat_inline(split_by_block[0]);

    for (let i = 0; i < block_matches.length; i++) {
      return_div = <div>
      {return_div}
      <div className="blocked-math">
      <Latex>{block_matches[i]}</Latex>
      </div>
      {this.reformat_inline(split_by_block[i+1])}
      </div>
    }

    this.state = {
      content: return_div
    }}
    
  }



  // format block with inline
  reformat_inline(block){
    var return_div = <Latex> Place holder content </Latex>;
    var inline_matches = block.match(inline_pattern);
    var split_by_inline = block.split(inline_pattern);

    if( inline_matches != null){ //initial splitting
      return_div =<div>
      <ReactMarkdown
      components={{code({node, className, children, ...props})
       {return <SyntaxHighlighter 
        {...props} 
        children={String(children)} 
        style={materialDark}
        language={className.match(/language-(\w+)/)[1]}
        PreTag="div"/>}}
       }>{split_by_inline[0]}</ReactMarkdown></div>

      for (let j = 0; j < inline_matches.length; j++) {
        return_div = <div>
        {return_div}
        <div className="inline-math">
        <Latex id = "latex_inline">{inline_matches[j]}</Latex>
        </div>
        <ReactMarkdown
          id = "markdown_inline"
          components={{code({node, className, ...props})
          {return <SyntaxHighlighter
            {...props}
            style={materialDark}
            language={className.match(/language-(\w+)/)[1]}
            PreTag="div"/>}}
          }>  {split_by_inline[j+1]}</ReactMarkdown>
        </div>
      }
      return_div = <div>
      {return_div}
      <ReactMarkdown
        components={{
          code({node, className, ...props}) {
            return <SyntaxHighlighter
              {...props}
              style={materialDark}
              language={className.match(/language-(\w+)/)[1]}
              PreTag="div"/>;
          }
        }}>
        {split_by_inline[split_by_inline.length - 1]}
      </ReactMarkdown>
      </div>


    }else{
      return_div =<ReactMarkdown 
      components={{code({node, className, ...props})
      {return <SyntaxHighlighter
        {...props}
        style={materialDark}
        // language={className.match(/language-(\w+)/)[1]}
        PreTag="div"/>}}
      }>{block}</ReactMarkdown>
    }
    return return_div;
  }


  render() {
    return (
      <div>
        {this.state.content}
      </div>
    );
  }
}

export default Markdown;


/* 
<ReactMarkdown
            components={{
            code({node, inline, className, children, ...props}) {
                const match = /language-(\w+)/.exec(className || '')
                return !inline && match ? (
                <SyntaxHighlighter
                    {...props}
                    children={String(children)}
                    style={materialDark}
                    language={match[1]}
                    PreTag="div"
                />
                ) : (
                <code {...props} className={className}>
                    {children}
                </code>
                )
              } 
            }
            
          }
          
            {...this.props}
          />
          <div>
            {this.state.inline_math}
          </div>
          <div>
            {this.state.block_math}
          </div>
          
          
          <p>
            To show it can be done
          </p>
          <Latex>$a_1 + b_2$</Latex>
          </div> */