import {react} from 'react';
import React from 'react';

export class Flows extends React.Component{


    render(){
        return(
            <div>
                <h1>Flows</h1>
                <p>Flows are the way to go</p>
            </div>
        );
    }
}