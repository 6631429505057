import React from "react";
//import { Button, Form, FormGroup, Input, Label } from "reactstrap";

import axios from "axios";

import { ACADEMIA_API_URL } from "../constants";
import { Course } from "./Course";


export class Axioms extends React.Component{
  state = {
    pk: 0,
    name: "",
    email: "",
    document: "",
    phone: "",
    courses: [],
    num_courses:0,
  };

  
  add_course = () => {
    this.setState({courses: [...this.state.courses,
    <div id={this.state.num_courses}>
      <button id={this.state.num_courses} onClick={this.remove_course}>Remove Course</button>
    <Course id = {this.state.num_courses} key={this.state.courses.length}></Course>
    </div>],
    num_courses: this.state.num_courses+1});
  }

  remove_course = e => {
    console.log(e.target.id);
    // this.state.courses.splice(parseInt(e.target.key), 1)
    this.setState({courses: this.state.courses.filter((element)=> element.props.id !== parseInt(e.target.id))});
    
  }

  componentDidMount() {
    if (this.props.student) {
      const { pk, name, document, email, phone } = this.props.student;
      this.setState({ pk, name, document, email, phone });
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  createStudent = e => {
    e.preventDefault();
    axios.post(ACADEMIA_API_URL, this.state).then(() => {this.toggle()});;
  };

  editStudent = e => {
    e.preventDefault();
    axios.put(ACADEMIA_API_URL + this.state.pk+'/', this.state).then(() => {this.toggle()});
  };

  toggle() {
    this.setState({pk:this.state.pk+1, name: "", email: "", document: "", phone: ""});
  };

  defaultIfEmpty = value => {
    return value === "" ? "" : value;
  };

  render() {
    return (
      <div>
      {/* <Form onSubmit={this.props.student ? this.editStudent : this.createStudent}>
        <FormGroup>
          <Label for="name">Name:</Label>
          <Input
            type="text"
            name="name"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.name)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="email">Email:</Label>
          <Input
            type="email"
            name="email"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.email)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="document">Document:</Label>
          <Input
            type="text"
            name="document"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.document)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="phone">Phone:</Label>
          <Input
            type="text"
            name="phone"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.phone)}
          />
        </FormGroup>
        <Button>Send</Button>
      </Form> */}

      <button onClick={this.add_course}>Add Course</button>
      {
        this.state.courses
      }
      
      </div>
    );
  }
}

// export default NewStudentForm;