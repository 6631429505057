import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';

export class Docs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPage: 'getting-started'
        };
    }

    handlePageSelect = (page) => {
        this.setState({ selectedPage: page });
    };

    renderPageContent = () => {
        switch (this.state.selectedPage) {
            case 'introduction':
                return <h2>Introduction</h2>;
            case 'getting-started':
                return <h2>Getting Started</h2>;
            case 'usage':
                return <h2>Usage</h2>;
            default:
                return <h2>Page not found</h2>;
        }
    };
    render() {
        return (
            <div style={{ display: 'flex' }}>
                <div
                    style={{
                        padding: '10px',
                        width: '30%',
                        background: '#f0f0f0'
                    }}
                >
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        <li>
                            <Link to="/docs/introduction" onClick={() => this.handlePageSelect('introduction')}>
                                Introduction
                            </Link>
                        </li>
                        <li>
                            <Link to="/docs/getting-started" onClick={() => this.handlePageSelect('getting-started')}>
                                Getting Started
                            </Link>
                        </li>
                        <li>
                            <Link to="/docs/usage" onClick={() => this.handlePageSelect('usage')}>
                                Usage
                            </Link>
                        </li>
                    </ul>
                </div>

                <div style={{ flex: 1, padding: '10px' }}>
                    {this.renderPageContent()}
                </div>
            </div>
        );
    }
}