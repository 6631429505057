import axios from 'axios';
import React, { Component} from 'react';
import { GET_ANSWER, ISAAC_API_URL } from '../constants';
import { Collapse, Navbar, NavbarToggler } from 'reactstrap';
import './NavMenu.css';
import hashing_basics from './assets/Hashing and Dictionaries_default.mp4';
import { Markdown } from './Markdown';
import OpenAI from 'openai';
import { coerceInteger } from 'openai/core.mjs';
import { useRef } from 'react';

const assistantId = 'asst_sgKag2BgYagRVkzfCVvRj5hg';
const threadId = 'msg_bea8iFHZpzoI8LY9fz2xb9Sn';

const openai = new OpenAI({
    apiKey:'sk-fe_DRkn_VidzaqTg7as4k93HQqoC9LfZn_xlaF1EEPT3BlbkFJSmtOH4J7whq3fH_meoK6ZituDSkwbzeT9pMCr2zQkA',
    dangerouslyAllowBrowser: true,
  });




async function get_answer(conversation,question){

    const stream = await openai.beta.chat.completions.stream({
        model: 'gpt-4',
        messages: [...conversation, {"role":"user","content":question}],
        stream: true,
      });

    const chatCompletion = await stream.finalChatCompletion();

    return chatCompletion.choices[chatCompletion.choices.length - 1];
}

function get_video_time(videoRef){
    return videoRef.currentTime;
}

export class VideoChat extends Component {

    static displayName = VideoChat.name;

    constructor(props) {
        super(props);

        this.state = {
            content:`Hello Welcome to professional assistant beta`,
            response_data:'',
            said: '',
            conversation: [{"role":"system", "content":"Please tutor the user. "}], 
            loading: true,
            options_collapsed: true,
    
            is_video_reference: true,
            video_time: 0,


            md_render: true,
            content_medium:'md',
            context_rows:50,
            md_conversation:[],
            reference_collapsed:true,//0 nothing, 1 editing md content, 2 rendering md content
            edit_reference:true,
            reference_data:`
Some test data
`
            // thread: openai.beta.threads.create({})
        }
        this.handleChange = this.handleChange.bind(this);
        this.handlekeyPress = this.handlekeyPress.bind(this);
        this.clear = this.clear.bind(this);
        this.toggleSelectOptions = this.toggleSelectOptions.bind(this);
        this.render_md_content = this.render_md_content.bind(this);
        this.handleContentChange = this.handleContentChange.bind(this);
        this.set_medium = this.set_medium.bind(this);
        this.textareaRef = React.createRef();

        this.videoRef = React.createRef<HTMLVideoElement>(0);

        this.handle_reference_change = this.handle_reference_change.bind(this);
        this.open_reference = this.open_reference.bind(this);
        this.toggle_edit_reference = this.toggle_edit_reference.bind(this);

        this.handle_video_reference = this.handle_video_reference.bind(this);


    }
    toggleSelectOptions () {
        this.setState({
            options_collapsed: !this.state.options_collapsed
        });
      }

    handleChange(event) {
        this.setState({ 
            said: event.target.value,
            loading: false});
    }

    handle_reference_change(event) {
        this.setState({ 
            reference_data: event.target.value});
    }

    handleContentChange(event) {
        if (event.key === 'Enter'){

        }
        this.setState({ content: event.target.value });
    }

    setResponse_data(response_data) {
        this.setState({ response_data: response_data });
    }

    handlekeyPress(event) {
        if (event.key === 'Enter') {
            
            var content = this.state.content;
            var length = this.state.conversation.length;


            console.log(this.state.said);

            var completion = get_answer(this.state.conversation,this.state.said).then((completion) => {
                console.log(completion);
                this.setState({
                    conversation: [...this.state.conversation,
                    {"role":"user","content":this.state.said},
                    {"role":"assistant",
                    "content":completion.message.content}],

                    md_conversation:[...this.state.md_conversation,
                        <div style={{ textAlign: "right", border: "1px solid lightblue", borderRadius: "5px", padding: "5px", marginBottom: "10px" }}>
                            <span style={{ color: "blue" }}>👤</span> {/* Display the client's icon */}
                            <Markdown content={this.state.said}></Markdown>
                        </div>,
                        <div style={{ textAlign: "left", border: "1px solid lightgreen", borderRadius: "5px", padding: "5px", marginBottom: "10px" }}>
                            <span >🤖</span> {/* Display the assistant's icon */}
                            <Markdown content={completion.message.content}></Markdown>
                        </div>],
                    said:''
                    }
                )});
        

        }

    }

    render_md_content(event){
        this.setState({
            md_render: true
        });
    }

    set_medium(medium) {
        this.setState({
            content_medium: medium
        });
    }

    open_reference(){
        if (this.state.reference_collapsed){
            this.setState({
                reference_collapsed: false
            });
        }else{
            this.setState({
                reference_collapsed: true
            });
        }
    }

    handle_video_reference(){
        if (this.state.is_video_reference){
            this.setState({
                is_video_reference: false
            });
        }else{
            this.setState({
                is_video_reference: true
            });
            // this.videoRef.play();
            console.log(this.videoRef);
        }
    }

    updateTime(event){
        if (this.videoRef){
            console.log(this.videoRef.video_time);
            this.setState({
                video_time: this.videoRef.video_time
            });           
        }

        // console.log("New time")
    }

    onVideoPlay(){
        console.log("Video playing");
        console.log(this.refs.video.currentTime);
    }


    toggle_edit_reference(){
        if (this.state.edit_reference){
            this.setState({
                edit_reference: false
            });
        }else{
            this.setState({
                edit_reference: true
            });
            console.log(this.videoRef.current);
        }
    }


    clear(event) {
        this.setState({
            md_conversation: [],
            conversation:[{"role":"system", "content":"You are a professional assistant. You are helping edit their profile to their liking. You are also helping craft thought pieces for a client's professional social media. You are in a markdown and latex rendered environment. Use this syntax to better communicate."}],
            said:''
        });
    }



    render() {
        
    return (
        <div>
        <div class="row align-items-start">
            <div>
            
                <div class="headingclear">
                    
                    <div class="clearholder">
                        <button class="btn" onClick={this.clear} style={{ float: 'right' }}>
                            <img src="clear.png" alt="Clear" title="Clear content" />
                        </button>

                        <button class="btn" onClick={this.open_reference} style={{ float: 'right' }} alt = "Reference content" title="Reference content">
                            <img src="reference_book.png" alt="Reference" />
                        </button>
                        <button class="btn" onClick={this.handle_video_reference} style={{ float: 'right' }} alt = "Reference content" title="Reference content">
                            <img height={20} src="video_icon.png" alt="Reference" />
                        </button>
                    </div>
                    <h3 style={{ textAlign: 'left' }}>Video assistant</h3>
                    <img></img>
                </div>

                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light></Navbar>

                <div className='referencediv'>
                    {!this.state.reference_collapsed ? (
                        this.state.edit_reference ? (
                            <div class="form-floating">
                                <textarea placeholder="Reference Data" class="form-control" id="floatingTextarea2" onBlur={this.toggle_edit_reference} value={this.state.reference_data} onChange={this.handle_reference_change} style={{ height: `${this.state.reference_data.split('\n').length * 1.5}rem`, width: '100%', overflow: 'hidden' }} onInput={this.autoResize}></textarea>
                                <label for="floatingTextarea2">Reference Data</label>
                            </div>

                        ) : <div onClick={this.toggle_edit_reference}>
                                <div style={{ color: 'grey' }}>Click to edit reference data</div>
                                <Markdown content = {this.state.reference_data}></Markdown>
                            </div>
                    ) : 
                    <div></div>}

                
                </div>


                <div className='contentdiv'>
                    {this.state.md_conversation}
                </div>
                
            </div>
            
            <div >
                {/* <Markdown content = '## Professional Assistant'></Markdown> */}

                
                <div class="row align-items-center">
                    <div class="col-md-10 form-group"> 
                        <input className="form-control input-sm" id="input" type='text' value={this.state.said} onChange={this.handleChange} onKeyDown={this.handlekeyPress}>
                        </input>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  }
}
