import React, { Component } from 'react';
import AdsComponent from './AdsComponent';

export class For_business extends Component {
  static displayName = For_business.name;

//TODO: Create a page that breaks apart arguments into a hierarchy of cuases or subclaims. Add auto-labeling features.
// Create a page that explains the tool and how to use it. Make an analog to math and how the base of math can be traced to first principles.
  render() {
    return (
      <div>
        <div>
          <AdsComponent dataAdSlot='X7XXXXXX5X' />
          
          <h1>For Business</h1>

          <p>This is a simple example of a React component.</p>

          </div>

      </div>
    );
  }
}
