import React, { Component } from 'react';
import { Markdown } from './Markdown';
import AdsComponent from './AdsComponent';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <div>
          <h1>Home</h1>

        </div>
      </div>
    );
  }
}
