import React, { Component} from 'react';
import ReactMarkdown from 'react-markdown';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {materialDark} from 'react-syntax-highlighter/dist/esm/styles/prism';
import Markdown from './Markdown';


export class Course extends Component{
    constructor(props){
        super(props);
        this.state = {
            key: props.id,
            title: props.id,
            toggle_edit: false,
            material:'Some test data'}
        }

    toggle_edit = () => {   
        this.setState({toggle_edit: !this.state.toggle_edit});
    }

    onChange = (e) => {
        this.setState({material: e.target.value});
    }

    render(){
        return(
            <div>
            <div>
                <h1>Course:{this.state.title}</h1>

                {this.state.toggle_edit ? (
                    <div class="form-floating" onBlur={this.toggle_edit}>
                        <textarea placeholder="Reference Data" onChange={this.onChange} value={this.state.material}>{this.material}</textarea>
                        
                    </div>
                ):(<div onClick={this.toggle_edit}>

                    {/^[a-z0-9]+$/i.test(this.state.material)}

                    {/[^\s\\]+$/i.test(this.state.material) ? (<div></div>):(<div>Click to edit course summary</div>)}
                    <Markdown content = {this.state.material}></Markdown>
                </div>)}

                <p>Competency score 0-100%</p>

               
            </div>
            <button id={this.state.key}> Launch Tutor</button>
            </div>
        );
    }
}