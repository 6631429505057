import axios from 'axios';
import React, { Component} from 'react';
import { GET_ANSWER, ISAAC_API_URL } from '../constants';
import { Collapse, Navbar, NavbarToggler } from 'reactstrap';
import './NavMenu.css';
import { Markdown } from './Markdown';

import AdsComponent from './AdsComponent';

export class Portfolio extends Component {
    static displayName = Portfolio.name;

    constructor(props) {
        super(props);
        this.state = {
            content : "# HW interview prep",
            connection_matrix:[[]],
            graph:[[]],
        };
      }
      
    render(){
        return (
        <div>
            <AdsComponent dataAdSlot='X7XXXXXX5X' />
            <div>
                <h1>Erik Huuki</h1>
                <p>Some random information about Erik
                </p>
                <h1>David</h1>
                <p>Some random information about David
                </p>
            </div>
            <div> 

            </div>
        </div>
        );
    }
}
  
